










// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, ref, watch } from '@vue/composition-api';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import Page from '@/components/Page.vue';
import PageRow from '@/components/PageRow.vue';
import SaasMembership from '@/components/saas/SaasMembership.vue';
import { nextTick } from 'process';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PageSaas',
  components: {
    Page,
    PageRow,
    SaasMembership
  },
  setup() {
    const workspacesService = usePodocoreModuleService('workspaces');

    // TODO : do something less trashy to reload the component
    const membershipIsVisible = ref(true);

    watch(workspacesService.state, (currentState) => {
      if (currentState.matches('success')) {
        membershipIsVisible.value = false;

        nextTick(() => {
          membershipIsVisible.value = true;
        });
      }
    });

    return {
      membershipIsVisible
    };
  }
});
