



























// Import vendors ----------------------------------------------------------------------------------
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  watch
} from '@vue/composition-api';
import { clone } from 'lodash';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule, usePodocoreModuleService } from '@/plugins/podocore';
// Import config -----------------------------------------------------------------------------------
import { saasConfig } from '@/config/saas.config';
// Import components -------------------------------------------------------------------------------
import AlertError from '@/components/alerts/AlertError.vue';
// Import utils ------------------------------------------------------------------------------------
import { useAnalytics } from '@/utils/analytics.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'PageSaas',
  components: {
    AlertError
  },
  setup(_, { root }) {
    const requestModule = usePodocoreModule('request');
    const workspacesModule = usePodocoreModule('workspaces');
    const { trackSuccess } = useAnalytics();

    const workspacesService = usePodocoreModuleService('workspaces');
    const doctorService = usePodocoreModuleService('doctor');

    const currentWorkspace = computed(() => {
      return (workspacesService.state.value as any).context.current;
    });

    const doctor = computed(() => {
      return (doctorService.state.value as any).context.doctor;
    });

    const saasPortalRequest = requestModule.useAuthenticatedRequest(
      `${saasConfig.default}/widget/subscription/manageByApplicationWorkspaceId/${currentWorkspace.value.cuid}`,
      {
        axios: {
          method: 'POST'
        }
      }
    );

    const portalInstanceDefault = {
      error: null,
      isPending: false,
      isLoaded: false,
      isSuccess: false,
      isClosed: false,
      hasActiveSubscription: false
    };

    const portalInstance = reactive(clone(portalInstanceDefault));

    watch(workspacesService.state, (currentState) => {
      if (currentState.matches('success')) {
        // XXXXX
      }
    });

    onBeforeMount(() => {
      saasPortalRequest.request();
    });

    onBeforeUnmount(() => {
      saasPortalRequest.cancel();

      workspacesModule.chargebee.logout();
    });

    const isSuccess = computed(() => {
      return !saasPortalRequest.error.value && !!saasPortalRequest.data.value;
    });

    watch(isSuccess, (value) => {
      if (value) {
        const portal = saasPortalRequest.data.value;

        const hasActiveSubscription: boolean = portal.linked_customers.find(
          (linked_customer: any) => linked_customer.customer_id === portal.customer_id
        ).has_active_subscription;

        if (hasActiveSubscription) {
          portalInstance.hasActiveSubscription = true;

          portalInstance.isPending = true;

          workspacesModule.chargebee.setPortalSession(() => Promise.resolve(portal));

          workspacesModule.chargebee.createChargebeePortal().open({
            loaded() {
              portalInstance.isPending = false;
              portalInstance.isLoaded = true;
            },
            subscriptionChanged() {
              portalInstance.isSuccess = true;
            },
            subscriptionCustomFieldsChanged() {
              portalInstance.isSuccess = true;
            },
            subscriptionCancelled() {
              portalInstance.isSuccess = true;
            },
            subscriptionResumed() {
              portalInstance.isSuccess = true;
            },
            subscriptionPaused() {
              portalInstance.isSuccess = true;
            },
            subscriptionReactivated() {
              portalInstance.isSuccess = true;
            },
            error(error: any) {
              portalInstance.error = error;
            },
            close() {
              portalInstance.isClosed = true;
            }
          });
        } else {
          portalInstance.hasActiveSubscription = false;
        }
      }
    });

    watch(portalInstance, async (value) => {
      if (value.isClosed) {
        await successReload(false);
      }
      if (value.isSuccess) {
        await successReload(true);
      }
    });

    async function successReload(reload: boolean) {
      await root.$router.push({ name: 'home' });
      if (reload) location.reload();
    }

    function redirectToPlanPicker() {
      const to_uri = new URL('https://www.digitsolepro.com/pricing');
      const redirect_uri = new URL(window.location.origin);

      redirect_uri.searchParams.append('workspaceCuid', currentWorkspace.value.cuid);
      redirect_uri.searchParams.append('doctorCuid', doctor.value.cuid);

      to_uri.searchParams.append('redirect_uri', redirect_uri.href);

      window.location.assign(to_uri.href);
    }

    function contactSales() {
      const to_uri = new URL('https://www.digitsolepro.com/contact');
      window.open(to_uri.href);
      // Analytics
      trackSuccess('Membership/Contact sales');
    }

    return {
      saasPortalRequest,
      portalInstance,
      // Methods
      redirectToPlanPicker,
      contactSales,
      // Utils
      trackSuccess
    };
  }
});
